<template>
  <div class="checkout-qec-methods">
    <div class="edit-correo">
      <p class="title-checkot-qec">Correo electrónico</p>
      <span class="edit" @click="$emit('editEmail')">Editar</span>
    </div>
    <input
      type="email"
      class="input-form-global payment-input mb-4"
      disabled
      :value="dataQEC.cart.client.email"
    />
    <p class="title-checkot-qec">Seleccione el método de pago</p>
    <div class="container-methdos-payment" role="tablist">
      <div class="mb-3">
        <b-card-header
          :style="
            paymentMethods['pse'] && !paymentMethods['pse']?.blocked
              ? ''
              : 'opacity: .6'
          "
          :title="
            !paymentMethods['pse']?.blocked
              ? 'PSE'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-3 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #004983">
              <img src="@/assets/icons/tipoPago/icon-pse.png" />
            </div>
            <p class="text-header-acordeon">
              <b>PSE</b> <br />
              <span style="font-size: 14px">Transferencia electrónica</span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="paymentMethods['pse'] && !paymentMethods['pse']?.blocked"
          id="acordeon-3"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-p-s-e
              v-if="paymentMethods['pse']"
              :data-payment="paymentMethods['pse']"
              :pse="!paymentMethods['pse'].blocked"
            />
          </div>
        </b-collapse>
      </div>
      <div class="mb-3">
        <b-card-header
          :style="
            paymentMethods['efecty'] && !paymentMethods['efecty']?.blocked
              ? ''
              : 'opacity: .6'
          "
          :title="
            !paymentMethods['efecty']?.blocked
              ? 'Efecty'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-4 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #ffd919">
              <img src="@/assets/icons/tipoPago/icon-efecty.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Efecty</b> <br />
              <span style="font-size: 14px">
                Paga en efectivo en puntos efecty
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="paymentMethods['efecty'] && !paymentMethods['efecty']?.blocked"
          id="acordeon-4"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-efecty
              v-if="paymentMethods['efecty']"
              :efecty="!paymentMethods['efecty'].blocked"
              :data-payment="paymentMethods['efecty']"
              :cash="parseInt(dataQEC.cart.total_value)"
            />
          </div>
        </b-collapse>
      </div>
      <div class="mb-3">
        <b-card-header
          :style="
            paymentMethods['tarjeta'] && !paymentMethods['tarjeta']?.blocked
              ? ''
              : 'opacity: .6'
          "
          :title="
            !paymentMethods['tarjeta']?.blocked
              ? 'Tarjeta crédito / débito'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-6 block class="container-header-acordeon">
            <div class="img-header-acordeon">
              <img src="@/assets/icons/tipoPago/icon-tarjeta.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Tarjeta crédito / débito</b> <br />
              <span style="font-size: 14px">
                Recibimos todas las tarjetas
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="
            paymentMethods['tarjeta'] && !paymentMethods['tarjeta']?.blocked
          "
          id="acordeon-6"
          v-model="isCreditCard"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-credit-card
              v-if="paymentMethods['tarjeta']"
              :is-selected="isCreditCard"
              :data-payment="paymentMethods['tarjeta']"
              :credit-card="!paymentMethods['tarjeta'].blocked"
            />
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentEfecty from './methods/PaymentEfecty.vue'
import PaymentPSE from './methods/PaymentPSE.vue'
import PaymentCreditCard from './methods/PaymentCreditCard.vue'
import { BCardHeader } from 'bootstrap-vue'
import { mapState } from 'vuex'
export default {
  name: 'PaymentMethods',
  components: { PaymentEfecty, PaymentPSE, PaymentCreditCard, BCardHeader },
  data() {
    return {
      optionSelect: 0,
      isCreditCard: false,
    }
  },
  computed: {
    ...mapState('qec', ['dataQEC']),
    paymentMethods() {
      if (!this.dataQEC) return null
      let paymentMethods = {}
      this.dataQEC.payment_methods.forEach(paymentMethod => {
        paymentMethods[paymentMethod.code] = paymentMethod
      })
      return paymentMethods
    },
  },
  methods: {
    selectCreditCard() {
      this.paymentMethods['tarjeta'] && !this.paymentMethods['tarjeta'].blocked
        ? this.optionSelect == 6
          ? (this.optionSelect = 0)
          : (this.optionSelect = 6)
        : ''
    },
  },
}
</script>
<style>
.checkout-qec-methods {
  max-width: 500px;
  margin: auto;
}
.checkout-qec-methods .edit-correo {
  display: flex;
  justify-content: space-between;
}
.checkout-qec-methods .edit-correo .edit {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.checkout-qec-methods .edit-correo .edit:hover {
  font-weight: bold;
}
</style>
