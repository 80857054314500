<template>
  <div class="container-summary-order">
    <p class="title-checkot-qec">Datos de entrega</p>
    <p>
      <b>Nombre del cliente:</b> <br />
      {{ basicDataCart.client.name }} <br />
      {{ basicDataCart.client.typeDoc }} {{ basicDataCart.client.nit }} <br />
    </p>
    <p class="mb-4">
      <b>Dirección de entrega:</b> <br />
      {{ basicDataCart.client.address }} <br />
      {{ basicDataCart.client.city }}, {{ basicDataCart.client.departament }}
      <br />
    </p>
    <p class="title-checkot-qec">Resumen de compra</p>
    <div class="container-products">
      <div
        v-for="(product, index) in basicDataCart.items"
        :key="index"
        class="container-product"
      >
        <img :src="product.image" class="img-product" :alt="product.name" />
        <div class="container-detail-product">
          <p class="title-product-cart">
            {{ product.name }}
          </p>
          <p class="reference-product">
            Talla: {{ product.size }} - {{ product.color }}
          </p>
          <p class="count-product">Cantidad: {{ product.quantity }}</p>
          <p class="price-product">
            <b>
              {{ product.price_charged }}
            </b>
            <del v-if="product.price_sale">
              {{ product.price_sale }}
            </del>
          </p>
        </div>
      </div>
    </div>
    <div v-if="!hiddenSummary" class="container-total">
      <div class="price-container">
        <span>Subtotal</span>
        <b>
          {{ basicDataCart.subtotal }}
        </b>
      </div>
      <div class="price-container">
        <span>Envio</span>
        <b>
          {{ basicDataCart.shipping_value }}
        </b>
      </div>
      <div v-if="basicDataCart.discount" class="price-container">
        <span>Descuentos</span>
        <b>
          {{ basicDataCart.discount }}
        </b>
      </div>
      <hr />
      <div class="price-container">
        <b>Total</b>
        <b>
          {{ basicDataCart.total }}
        </b>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'SummaryOrder',
  props: {
    hiddenSummary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('qec', ['dataQEC']),
    basicDataCart() {
      return this.dataQEC?.cart ?? null
    },
  },
}
</script>
<style>
.container-summary-order {
  padding: 2rem 1rem;
  max-width: 500px;
  margin: auto;
}
.container-summary-order .container-products {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.container-summary-order .container-products .container-product {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}
.container-summary-order .container-products .container-product:last-child {
  margin-bottom: 0;
}
.container-summary-order .container-products .img-product {
  max-width: 110px;
  margin-bottom: auto;
  background-color: aliceblue;
  aspect-ratio: 961/1088;
}
.container-summary-order .container-products .container-detail-product {
  width: 70%;
}
.container-summary-order
  .container-products
  .container-detail-product
  .title-product-cart {
  cursor: text;
  font-weight: bold;
  font-family: 'PT Sans Bold', sans-serif;
  margin-bottom: 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  --webkit-line-clamp: 1;
}
.container-summary-order .container-products .reference-product {
  margin-bottom: 0.3rem;
}
.container-summary-order .container-products .count-product {
  margin-bottom: 0.3rem;
}
.container-summary-order .container-products .price-product {
  margin-bottom: 0;
}
.container-summary-order .container-total {
  background-color: white;
  padding: 1rem;
}
.container-summary-order .container-total .price-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}
</style>
