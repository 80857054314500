var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.summaryData)?_c('div',{staticClass:"summary-qec"},[_c('div',{staticClass:"bg-content-icon-response",class:[
      _vm.summaryData.state_pol == 4
        ? 'approved'
        : _vm.summaryData.state_pol > 4
        ? 'rejected'
        : 'pending',
    ]},[_c('div',{staticClass:"content-icon-response"},[(_vm.summaryData.state_pol == 4)?_c('img',{attrs:{"src":require("@/assets/icons/shield-check.svg"),"alt":"icon transaction"}}):(_vm.summaryData.state_pol > 4)?_c('img',{attrs:{"src":require("@/assets/icons/shield-exclamation.svg"),"alt":"icon transaction"}}):_c('img',{attrs:{"src":require("@/assets/icons/shield-pending.svg"),"alt":"icon transaction"}})])]),_c('p',{staticClass:"title-checkot-qec"},[_vm._v(_vm._s(_vm.summaryData.title))]),(_vm.summaryData.state_pol == 4)?_c('p',{staticClass:"subtitle-container"},[_vm._v(" Se ha registrado tu pago ")]):(_vm.summaryData.state_pol > 4)?_c('p',{staticClass:"subtitle-container"},[_vm._v(" No se ha podido validar tu pago. ")]):_c('p',{staticClass:"subtitle-container"},[_vm._v(" Este proceso puede tomar unos minutos. Te avisaremos en cuanto se complete. ¡Gracias por tu paciencia! ")]),_c('div',{staticClass:"container-summary-qec"},[_c('summary-order',{attrs:{"hidden-summary":""}}),_c('div',{staticClass:"container-summary"},[_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Referencia de pago")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.reference_payment))])]),_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Referencia de la transacción")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.reference_transaction))])]),_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Fecha")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.date))])]),_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Valor total")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.total))])]),_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Descripción")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.description))])]),_c('p',{staticClass:"description-summary"},[_c('b',[_vm._v("Entidad")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.summaryData.payment_method))])])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }