<template>
  <div class="content-404">
    <div class="content-card">
      <span class="text-status-error">
        {{ errorStateService.status }}
      </span>
      <h2>
        {{ errorStateService.title }}
      </h2>
      <p>
        {{ errorStateService.message }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('qec', ['errorStateService']),
  },
}
</script>
<style lang="scss">
.content-404 {
  padding: 2rem;
  .content-card {
    overflow: auto;
    background-color: #ece4e9;
    width: 90%;
    padding: 4rem 2rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50px;
    .text-status-error {
      font-size: 200px;
      color: #937788;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 100px;
      }
    }
    h2 {
      font-size: 50px;
      width: 100%;
      text-align: center;
      color: #937788;
      margin-bottom: 3rem;
    }
    p {
      max-width: 550px;
      text-align: center;
      font-size: 18px;
      color: #937788;
    }
  }
  @media (max-width: 991px) {
    padding: 2rem 1.5rem;
    .content-card {
      width: 100%;
      padding: 4rem 1rem;
      h2 {
        font-size: 40px;
      }
    }
  }
}
</style>
