<template>
  <div class="checkout-qec-email">
    <p class="title-checkot-qec">Correo electronico</p>
    <div class="container-qec-email-input">
      <label for="email">
        Antes de continuar con el pago, confirma tu correo electrónico
      </label>
      <input
        v-model="email"
        class="input-form-global payment-input"
        type="email"
        placeholder="Correo electrónico"
        @focus="validateEmailPayment = false"
        @blur="validateEmailPaymentMethod()"
      />
      <!-- <span class="error">{{ errorMessageEmail }}</span> -->
    </div>
    <p class="title-checkot-qec">¿Por qué solicitamos tu correo?</p>
    <ul class="list-checkot-qec">
      <li>
        Recibirás la confirmación del pago exitoso.
      </li>
      <li>
        Te notificamos el estado de tu pedido en tiempo real.
      </li>
      <li>
        Te avisamos cuando tu compra salga despachada.
      </li>
    </ul>
    <button
      class="bnt-primary"
      :disabled="email == '' || validateEmailPayment"
      @click="sendValidationEmail()"
    >
      Continuar
    </button>
    <b-modal
      id="validateemail"
      v-model="showModal"
      centered
      hide-footer
      hide-header
    >
      <div class="p-4 d-flex flex-wrap">
        <p class="text-center mb-4 w-100">
          {{ errorMessageEmail }}
        </p>
        <button
          class="bnt-primary mx-auto col-12 col-md-6"
          @click="showModal = false"
        >
          Entendido
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CheckoutQECEmail',
  data() {
    return {
      email: '',
      validateEmailPayment: false,
      showModal: false,
    }
  },
  computed: {
    ...mapState('qec', ['errorMessageEmail', 'dataQEC']),
    validateEmail: function() {
      /* eslint-disable */
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
  },
  mounted() {
    this.email = this.dataQEC.cart.client.email
  },
  methods: {
    ...mapActions('qec', ['validateEmailService']),
    validateEmailPaymentMethod() {
      this.validateEmailPayment = !this.validateEmail
      this.$store.state.qec.errorMessageEmail = ''
    },
    async sendValidationEmail() {
      this.$store.state.loaderClover = true
      await this.validateEmailService({
        token: this.$route.params.tokenCart,
        email: this.email
      }).then(() => {
        this.$store.state.loaderClover = false
        if (this.dataQEC.cart.client.verified_email && !this.errorMessageEmail) {
          this.$emit('editEmail', true)
        } else {
          this.showModal = true
        }
      })
    },
  },
}
</script>
<style>
.checkout-qec-email {
  max-width: 500px;
  margin: auto;
}
.container-qec-email-input {
  width: 100%;
  margin-bottom: 2rem;
}
.container-qec-email-input .error {
  color: red;
}
.list-checkot-qec {
  margin-bottom: 2rem;
  padding-left: 1rem;
}
.list-checkot-qec li {
  margin-bottom: 0.2rem;
}
.bnt-primary {
  width: 70%;
}
.bnt-primary:disabled {
  opacity: 0.7;
  cursor: default;
}
</style>
