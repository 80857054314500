<template>
  <div>
    <div class="container-form col-12 mx-auto">
      <form id="form-checkout">
        <label>Número de tarjeta</label>
        <div id="form-checkout__cardNumber" class="input-quac_tj" />
        <div class="d-flex flex-wrap">
          <div class="col-6 pe-1 ps-0">
            <label>Fecha de vencimiento</label>
            <div id="form-checkout__expirationDate" class="input-quac_tj" />
          </div>
          <div class="col-6 ps-1 pe-0">
            <label>CVV</label>
            <div id="form-checkout__securityCode" class="input-quac_tj" />
          </div>
        </div>
        <select id="form-checkout__installments" class="input-quac_tj mb-1">
        </select>
        <div class="col-12 px-2 px-xl-3 mb-4 d-flex my-3 align-items-center">
          <b-link
            id="link-popover"
            style="cursor: pointer; text-decoration: none; color: black"
          >
            <img class="pe-3" src="@/assets/icons/checkout/credit-card.svg" />
            ¿Dónde encuentro el código CVV?</b-link
          >
          <b-popover placement="bottom" target="link-popover" triggers="hover">
            <p><b>Código de seguridad CVV</b></p>
            <p style="font-size: 14px">
              Los últimos tres (3) o cuatro (4) dígitos del número impreso al
              respaldo de tu tarjeta corresponden al código de seguridad (CVV) o
              CVV2. Si tienes una tarjeta de crédito American Express, el código
              lo encuentra en la parte delantera.
            </p>
          </b-popover>
        </div>
        <select id="form-checkout__issuer" class="input-quac_tj"></select>
        <label>Datos del pagador</label>
        <div class="d-flex flex-wrap">
          <div class="col-6 pe-1 ps-0">
            <select
              id="form-checkout__identificationType"
              class="input-quac_tj"
            >
            </select>
          </div>
          <div class="col-6 ps-1 pe-0">
            <input
              id="form-checkout__identificationNumber"
              type="text"
              class="input-quac_tj"
            />
          </div>
        </div>
        <label>Nombre y apellido como aparece en la tarjeta</label>
        <input
          id="form-checkout__cardholderName"
          type="text"
          class="input-quac_tj"
        />
        <input
          id="form-checkout__cardholderEmail"
          type="email"
          class="input-quac_tj d-none"
        />
        <button
          id="form-checkout__submit"
          type="submit"
          class="bnt-primary w-100 mt-4"
        >
          Finalizar compra
        </button>
        <div v-if="errorCreditCard" class="my-3">
          <span class="text-error" v-html="errorCreditCard"></span>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import qecAxios from '@/plugins/qecAxios'
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    dataPayment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      invalidName: false,
      nCreditCard: '',
      isValidCard: false,
      invalidNCreditCard: false,
      tipoDoc: null,
      invalidTipoDoc: false,
      doc: '',
      invalidDoc: false,
      month: null,
      invalidMonth: false,
      year: null,
      invalidYear: false,
      ccv: '',
      invalidCcv: false,
      nCuotas: null,
      invalidNcuotas: false,
      typeCard: '',
      errorCreditCard: '',
    }
  },
  computed: {
    ...mapState('qec', ['dataQEC']),
  },
  watch: {
    isSelected() {
      this.errorCreditCard = ''
      if (!this.isSelected && window.cardFormMercadoPagoQEC) {
        window.cardFormMercadoPagoQEC.unmount('#form-checkout')
      }
      if (this.isSelected) {
        this.createForm()
      }
    },
  },
  mounted() {
    if (window.cardFormMercadoPagoQEC)
      window.cardFormMercadoPagoQEC.unmount('#form-checkout')
  },
  unmounted() {
    if (window.cardFormMercadoPagoQEC)
      window.cardFormMercadoPagoQEC.unmount('#form-checkout')
  },
  methods: {
    ...mapActions('qec', ['getCartQEC']),
    async sendPaymentCreditCard(payload) {
      this.$store.state.loaderClover = true
      this.errorCreditCard = ''
      await qecAxios
        .post(
          'cart/payment',
          {
            token: this.$route.params.tokenCart,
            payment_method: 'tarjeta',
            payload: {
              token_card: payload.token,
              type_doc: this.tipoDoc,
              identification: this.doc,
              card_number: this.nCreditCard.replaceAll(' ', ''),
              security_code: this.ccv,
              card_expiration_month: this.month ?? '',
              card_expiration_year: this.year ?? '',
              card_holder_name: this.name,
              payment_method_id_card: payload.paymentMethodId ?? this.typeCard,
            },
          },
          {
            headers: {
              'X-meli-session-id': window.MP_DEVICE_SESSION_ID,
            },
          },
        )
        .then(response => {
          this.$store.state.loaderClover = false
          if (response.data.success) {
            location.reload()
          }
        })
        .catch(err => {
          this.$store.state.loaderClover = false
          this.errorCreditCard = err.response.data.message
        })
    },
    createForm() {
      const mp = new window.MercadoPago(this.dataPayment.credentials.public_key)
      window.cardFormMercadoPagoQEC = mp.cardForm({
        amount: this.dataQEC.cart.total_value.toString(),
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Numero de tarjeta',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de seguridad',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular de la tarjeta',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emisor',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Cuotas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número del documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error)
              return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onSubmit: event => {
            window.cardFormMercadoPagoQEC.createCardToken()
            event.preventDefault()
            const {
              paymentMethodId,
              issuerId,
              token,
            } = window.cardFormMercadoPagoQEC.getCardFormData()
            this.sendPaymentCreditCard({ paymentMethodId, issuerId, token })
          },
          onFetching: resource => {
            console.log('Fetching resource: ', resource)
          },
        },
      })
    },
  },
}
</script>
<style>
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  font-size: 15px;
}
#form-checkout label {
  width: 100%;
  text-align: left;
  padding-left: 0.1rem;
  margin-bottom: 0.2rem;
}
#form-checkout > .input-quac_tj,
#form-checkout .input-quac_tj {
  height: 18px;
  display: flex;
  background: #f5f5f5;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  padding-right: 1rem;
  padding-left: 1rem;
  min-height: 50px;
  border-radius: 0;
  margin-bottom: 1rem;
  width: 100%;
  max-height: 50px;
}
#form-checkout .progress-bar {
  width: 100%;
  background-color: #e9f0fd;
}
</style>
