<template>
  <div class="payment-efecty-baloto">
    <p class="mb-4 text-center">
      El código para pago se mostrará al finalizar la compra. Ten en cuenta que
      el plazo máximo para realizar el pago en cualquier punto <b>Efecty</b> o
      pago en banco es de 48 horas, pasado este plazo tu pedido será cancelado
      automáticamente.* Recuerda que el pago mínimo es de $20.000 pesos.
    </p>
    <div class="col-12 col-lg-10 mx-auto d-flex flex-wrap mx-auto">
      <button
        class="bnt-primary w-100 mb-4"
        :disabled="cash < 20000"
        @click="sendData()"
      >
        Pagar con Efecty
      </button>
      <div v-if="errorMessageEfecty" class="text-center w-100">
        <p class="text-error">{{ errorMessageEfecty }}</p>
      </div>
    </div>
    <p style="font-size: 12px" class="text-center">
      No demores en pagar, solo podemos reservarte los productos cuando el pago
      se acredite
    </p>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    efecty: {
      type: Boolean,
      default: true,
    },
    cash: {
      type: Number,
      default: 0,
    },
    dataPayment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('qec', ['errorMessageEfecty']),
  },
  methods: {
    ...mapActions('qec', ['paymentQECEfecty']),
    async sendData() {
      this.$store.state.loaderClover = true
      await this.paymentQECEfecty({
        token: this.$route.params.tokenCart,
      }).then(() => {
        this.$store.state.loaderClover = false
      })
    },
  },
}
</script>
