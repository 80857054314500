<template>
  <div class="container-payment-pse text-center">
    <p class="w-100">
      <b>Selecciona la entidad financiera</b>
    </p>
    <p class="mb-4">
      Selecciona la entidad financiera para continuar con el pago, recuerda que
      debes tener una cuenta habilitada para realizar pagos por internet.
    </p>
    <div class="col-12 col-lg-10 mx-auto mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidBank ? 'invalid' : ''"
          class="label-input"
          :style="bank ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Entidad bancaria</label
        >
        <select
          v-model="bank"
          :class="invalidBank ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('bank')"
          @focus="invalidBank = false"
        >
          <option :value="null">Entidad bancaria</option>
          <option
            v-for="(banco, index) in bancos"
            :key="index"
            :value="banco.pseCode"
          >
            {{
              banco.description
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </option>
          <span v-if="invalidBank" class="inavalida-input">{{
            bank ? 'Apellido invalido' : 'Campo requerido'
          }}</span>
        </select>
        <span v-if="invalidBank" class="inavalida-input">Campo requerido</span>
      </div>
    </div>
    <div class="col-12 col-lg-10 mx-auto mb-4">
      <button class="bnt-primary w-100" @click="sendData()">
        Pagar con PSE
      </button>
    </div>
    <div v-if="errorPaymentPSE">
      <span class="text-error">{{ errorPaymentPSE }}</span>
    </div>
    <p style="font-size: 12px" class="text-center w-100">
      Los pagos con este medio se acreditan como máximo en 1 hora
    </p>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      bancos: [],
      bank: null,
      invalidBank: false,
      errorPayment: false,
      msgErrorPaymentPSE: '',
    }
  },
  computed: {
    ...mapState('qec', ['errorPaymentPSE']),
    validateBank() {
      return this.bank
    },
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/payment/payu/listar_bancos_pse',
    })
      .then(response => {
        this.bancos = response.data.bancos
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    ...mapActions('qec', ['paymentQECPSE']),
    async sendData() {
      if (this.validateBank) {
        this.$store.state.loaderClover = true
        await this.paymentQECPSE({
          token: this.$route.params.tokenCart,
          payload: {
            doc_financial_institution: this.bank,
          },
        }).then(() => {
          this.$store.state.loaderClover = false
        })
      } else {
        this.invalidBank = true
      }
    },
    validateBlur(input) {
      if (input == 'bank') {
        this.invalidBank = !this.validateBank
      }
    },
  },
}
</script>
